import React from "react";
import { getSearchableModel } from "../utils";
import { useQueryCompany, useQueryExtraFieldValues } from "./queryHooks/companyQueries";
import useCompanyId from "./useCompanyId";

export default function useSearchableModel({companyId} = {}) {
    const { companyId: _companyId } = useCompanyId()
    companyId = companyId || _companyId
    const {
        data: extraFieldValues = {},
    } = useQueryExtraFieldValues({companyId})
    const { data: company } = useQueryCompany({ companyId })
    const schema = company?.settings?.procedure_extra_fields_schema
    
    const searchableModel = React.useMemo(() => {
        return getSearchableModel(schema, extraFieldValues)
    }, [schema, extraFieldValues])
    
    return {searchableModel, extraFieldValues}
}
