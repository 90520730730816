import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Avatar, Badge, Grid, IconButton, Toolbar } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/Notifications'
import NotificationsMenu from './NotificationsMenu'
import { companiesQueries, notificationQueries, profileQueries, queryClient } from '../networking'
import { getInitials } from '../utils'
import FibaLogoTesto from '../assets/Fiba_logo_testo.png'
import { useCompanyStore, useUserProfileStore } from '../stateManagement'
import { backend_url } from '../constants'
import { CompanySelect } from './CompanySelect'
import useCompanyId from '../customHooks/useCompanyId'

const useStyles = makeStyles((theme) => ({
    toolbar: {
        justifyContent: 'space-between',
    },
    leftButton: {
        // marginRight: theme.spacing(2),
    },
    avatar: {
        background: theme.palette.background.paperLight,
        color: theme.palette.common.white,
        width: 40,
        height: 40,
    },
    consentLink: {
        color: theme.palette.secondary.main,
        '&:hover': {
            textDecoration: 'underline',
        },
        '&hover': {},
    },
}))

export default function TopBar({ onHamburgerClick }) {
    const classes = useStyles()
    const history = useHistory()
    const urlLocation = useLocation().pathname
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [isSuperAdmin, setProfile, profile] = useUserProfileStore((state) => [
        state.isSuperAdmin,
        state.setProfile,
        state.profile,
    ])
    const [setCompanies, setDashboardCompanies, setWriteCompanies] = useCompanyStore((state) => [
        state.setCompanies,
        state.setDashboardCompanies,
        state.setWriteCompanies,
    ])

    const profileQuery = useQuery(profileQueries.getUser.name, profileQueries.getUser.fn, {
        onSuccess: (data) => setProfile(data),
    })

    const { companyId } = useCompanyId()
    useQuery(companiesQueries.getCompanies.name, () => companiesQueries.getCompanies.fn(), {
        enabled: profileQuery.isSuccess,
        onSuccess: (data) => {
            if (isSuperAdmin) {
                setCompanies(data)
                setDashboardCompanies(data)
                setWriteCompanies(data)
                return
            }

            const companies = data
            const dashboardCompanies = []
            const writeCompanies = []

            companies.forEach((company) => {
                if (company.admins.map((user) => user?.id).includes(profile.id)) {
                    dashboardCompanies.push(company)
                    writeCompanies.push(company)
                }
                if (company.members.map((user) => user?.id).includes(profile.id)) {
                    dashboardCompanies.push(company)
                }
            })
            setCompanies(data)
            setDashboardCompanies(dashboardCompanies)
            setWriteCompanies(writeCompanies)
        },
    })

    const { data: notifications = [] } = useQuery(
        notificationQueries.getNotifications.name,
        notificationQueries.getNotifications.fn,
        {
            refetchInterval: 60 * 1000, // REMEMBER: refetch query every minute!
            onSuccess: (data) => {
                data?.reverse()
                return data
            },
        }
    )

    const markAllNotificationsRead = useMutation(notificationQueries.markAllNotificationsRead.fn, {
        onSuccess: () => queryClient.invalidateQueries(notificationQueries.getNotifications.name),
    })

    const unreadNotificationsCount = notifications.filter((n) => n.read === false).length

    function handleNotificationsOpen(item) {
        setAnchorEl(item.currentTarget)
        if (unreadNotificationsCount > 0) {
            markAllNotificationsRead.mutate()
        }
    }

    function closeNotificationsMenu() {
        setAnchorEl(null)
    }

    function navigateToUserProfile() {
        if (urlLocation !== '/user/profile') history.push('/user/profile')
    }

    const leftElements = (
        <Grid
            item
            container
            wrap={'nowrap'}
            spacing={1}
            alignItems={'center'}
            justifyContent={'flex-start'}
            style={{ maxWidth: 'fit-content' }}
        >
            <Grid item>
                <IconButton
                    onClick={onHamburgerClick}
                    edge="start"
                    className={classes.leftButton}
                    aria-label="navigation menu"
                    color="inherit"
                >
                    <MenuIcon />
                </IconButton>
            </Grid>
            <Grid item>
                <Link
                    to={'/home' + (companyId ? `/${companyId}` : '')}
                    style={{ display: 'flex', marginLeft: -15 }}
                >
                    <img src={FibaLogoTesto} alt="" width={100} />
                </Link>
            </Grid>
        </Grid>
    )

    const rightElements = (
        <Grid
            item
            container
            wrap={'nowrap'}
            spacing={1}
            alignItems={'center'}
            justifyContent={'flex-end'}
            style={{ maxWidth: 'fit-content' }}
        >
            <Grid item>
                <IconButton onClick={handleNotificationsOpen} color="inherit">
                    <Badge
                        invisible={unreadNotificationsCount === 0}
                        badgeContent={unreadNotificationsCount}
                        overlap="circular"
                        color="secondary"
                    >
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={navigateToUserProfile} className={classes.leftButton}>
                    {profile && (
                        <Avatar
                            src={
                                profile?.image?.depot_url &&
                                new URL(profile.image.depot_url, backend_url)
                            }
                            alt={`${profile.first_name} ${profile.last_name}`}
                            className={classes.avatar}
                        >
                            {getInitials(profile.first_name, profile.last_name)}
                        </Avatar>
                    )}
                </IconButton>
            </Grid>
            <Grid item>
                <CompanySelect />
            </Grid>
        </Grid>
    )
    const hideTopBar = /^\/(login|privacy-policy|cookie-policy)/.test(urlLocation)
    return (
        <AppBar position="sticky" {...(hideTopBar && { style: { display: 'none' } })}>
            <Toolbar className={classes.toolbar}>
                {leftElements}
                {rightElements}
            </Toolbar>
            <NotificationsMenu
                open={Boolean(anchorEl)}
                closeMenu={closeNotificationsMenu}
                anchorElement={anchorEl}
                notifications={notifications}
            />
        </AppBar>
    )
}
