import React, { useMemo } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useQueryCompanyMethodologyExports } from "../customHooks/queryHooks/companyQueries";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { ErrorOutline, HourglassEmptyRounded, CloudDownload } from '@material-ui/icons'
import AppTooltip from "./AppTooltip";
import FileSaver from 'file-saver';
import { backend_url } from "../constants";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation } from "react-query";
import axios from "axios";
import useCompanyId from "../customHooks/useCompanyId";
import { queryClient } from "../networking";
import DeleteIcon from "@material-ui/icons/Delete";

function StatusCell({row}) {
    const { t } = useTranslation()
    
    const props = {
        failed: {
            title: t('error'),
            Icon: ErrorOutline,
            color: 'error'
        },
        completed: {
            title: t('download'),
            action: () => FileSaver.saveAs(String(new URL(row?.file?.depot_url, backend_url)), row?.file?.name),
            Icon: CloudDownload,
            // Icon: ErrorOutline,
            color: 'success'
        },
        waiting: {
            title: t('enqueued'),
            Icon: HourglassEmptyRounded,
            color: 'primary'
        },
        running: {
            title: t('elaborating'),
            Icon: ({color}) => <CircularProgress size={16} color={color} />,
            color: 'success'
        }
    }[row?.status]
    return <CellButton {...props}/>
}

function CellButton({title = "", action = () => {}, Icon = () => null, color = 'primary'}) {
    return (
        <TableCell>
            <AppTooltip title={title}>
                <IconButton
                    onClick={action}
                >
                    <Icon color={color} />
                </IconButton>
            </AppTooltip>
        </TableCell>
    )
}


function useTableColumns() {
    const {t} = useTranslation()
    const {companyId, beMethodology} = useCompanyId()
    const {mutate: deleteExport} = useMutation(
        ['deleteExport'],
        async (taskId) => {
            const resp = await axios.delete(`/companies/${companyId}/exports/${beMethodology}`, {data: {task_id: taskId}})
            return resp.data
        },
        {
            onSuccess: async () => await queryClient.invalidateQueries(['getCompanyMethodologyExports', companyId, beMethodology])
        }
    )
    return React.useMemo(() => [
        {
            field: 'id',
            hide: true,
        },
        {
            field: 'name',
            headerName: t('name'),
            width: 150
        },
        {
            field: 'export_type',
            headerName: t('export_type'),
            width: 200,
        },
        {
            field: 'created_at',
            headerName: t('created_at'),
            width: 200,
        },
        {
            field: 'status',
            headerName: t('status'),
            renderCell: ({ row }) => <StatusCell row={row} />,
            width: 100,
        },
        {
            field: 'delete',
            headerName: t('delete'),
            renderCell: ({ row }) => (
                <CellButton
                    title={t('delete')}
                    action={() => deleteExport(row.id)}
                    Icon={DeleteIcon}
                    color={'primary'}
                />
            ),
            width: 100
        }
    ], [t, deleteExport])
}
const useTableRows = () => {
    const { t } = useTranslation()
    const { data: exports } = useQueryCompanyMethodologyExports()
    return React.useMemo(() => (exports || [])
    .map((item) => ({
        ...item,
        created_at: item?.created_at || '',
        export_type: t((item?.task_handler_kwargs?.export_type)),
        name: item?.task_handler_kwargs?.name,
    }))
    .sort((a, b) => b.created_at.localeCompare(a.created_at)), [exports, t])
}

function ExportsTable() {
    const exportsTableColumns = useTableColumns()
    const exportsTableRows = useTableRows()
    return (
        <Grid item>
            
            <DataGrid
                columns={exportsTableColumns}
                rows={exportsTableRows}
                autoHeight
                autoPageSize
            
                // autosizeOnMount={true}
                // autosizeOptions={{
                //     columns: exportsTableColumns.map(col => col.field),
                //     includeOutliers: true,
                //     includeHeaders: true,
                // }}
            />
        </Grid>
    )
}

export default ExportsTable
function ExportsTableMui4() {
    const columns = useTableColumns()
    const rows = useTableRows()
    
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {columns.filter(({hide}) => !hide).map((column) => (
                            <TableCell key={column.field}>{column.headerName || column.field}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            {columns.filter(({hide}) => !hide).map((column) => (
                                column.renderCell ? (
                                    column.renderCell({row})
                                ) : (
                                    <TableCell sx={{...(column.width && {width: column.width})}}>{row[column.field]}</TableCell>
                                )
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}