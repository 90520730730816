import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { usePatchAnalysisVersionMutation } from "../../customHooks/mutationHooks/analysisMutations";
import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid } from "@material-ui/core";
import AppForm from "../AppForm";
import DynamicFormFields from "../FormItems/DynamicFormFields";
import { textType } from "../../FormConfigs/utils";
import { inputFile } from "../../FormConfigs/analysis_config";
import SaveIcon from "@material-ui/icons/Save";

export const AnalysisVersionCreationDialog = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [analysis, setAnalysis] = React.useState(null)
    React.useImperativeHandle(ref, () => ({
        close: () => setAnalysis(null),
        open: (analysis) => setAnalysis(analysis),
    }), [setAnalysis])
    

    const methods = useForm({
        mode: "onBlur",
    });
    // leave them like this as per https://react-hook-form.com/api#formState
    const { isDirty, isValid } = methods.formState;
    React.useEffect(() => {
        methods.reset({new_version: analysis?.active_version})
    }, [analysis])
    const { mutateAsync: createAnalysisVersion } = usePatchAnalysisVersionMutation();
    const submitNewAnalysisVersion = (data) => {
        console.log('submitting', data)
        createAnalysisVersion({ ...data, analysis_id: analysis?.id })
            .then(() => setAnalysis(null))
            .catch(() => console.error('Failed to create analysis'))
    };
    
    const scheduled_execution_type = analysis?.active_version?.configuration?.scheduled_execution_type;
    
    return (
        <Dialog
            open={!!analysis}
            onClose={() => setAnalysis(null)}
            aria-labelledby="create-analysis-version-form"
            fullWidth={true}
            maxWidth="md"
        >
            <AppForm onSubmit={submitNewAnalysisVersion} methods={methods}>
                <DialogTitle id="plc-active-configuration">
                    {t("plc_configuration.configuration")}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {/*
                            Se
                                - time_interval: impostare ogni quanti Secondi avviare l'analisi
                                - fixed_time: impostare data + ora
                            */}
                            <DynamicFormFields
                                fieldsKey={"new_version"}
                                formFields={{
                                    version_major_minor_patch: textType,
                                    ...(scheduled_execution_type && {
                                        configuration: {
                                            _type: "group",
                                            docker_image_url: textType,
                                            scheduled_execution_type: {
                                                ...textType,
                                                disabled: true
                                            },
                                            ...(scheduled_execution_type === "fixed_time" && {
                                                schedule_at_date: { _type: "dateTime" }
                                            }),
                                            ...(scheduled_execution_type === "time_interval" && {
                                                schedule_every_seconds: {
                                                    _type: "number",
                                                    step: 1,
                                                    min: 1
                                                }
                                            }),
                                            seconds_data_to_analyze: {
                                                _type: "number",
                                                step: 1,
                                                min: 1
                                            }
                                        }
                                    })
                                }}
                            />
                        </Grid>
                        <Grid container spacing={1} direction={"column"}>
                            {(analysis?.active_version?.configuration?.input_files || []).map(
                                (input_file, index) => {
                                    return (
                                        <>
                                            <Grid item>
                                                <DynamicFormFields
                                                    fieldsKey={`new_version.configuration.input_files[${index}]`}
                                                    formFields={{
                                                        ...inputFile,
                                                        name: { ...textType, disabled: true }
                                                    }}
                                                    // disableAll={true}
                                                />
                                            </Grid>
                                        </>
                                    );
                                }
                            )}
                        </Grid>
                        
                        {/*
                            In Base alla tipologia di versione analisi selezionata mostro il form di configurazione

                            Mostro menu con elenco versioni

                            "versions": [
                                {
                                    "version_major_minor_patch": "0.0.1",
                                    "scheduled_execution_type": "time_interval",
                                    "input_files": [
                                        {
                                            "name": "a.json",
                                            "content": {}
                                        }
                                    ]
                                },
                                {
                                    "version_major_minor_patch": "0.0.2",
                                    "scheduled_execution_type": "fixed_time",
                                    "input_files": [
                                        {
                                            "name": "input1.json",
                                            "content": {}
                                        }
                                    ]
                                }
                            ]

                            Selezionata la versione in base a: scheduled_execution_type
                            ->Se
                                - time_interval: impostare ogni quanti Secondi avviare l'analisi
                                - fixed_time: impostare data + ora

                            Se sono previsti input_files, mostrare preview/ possibilità di modificare

                        */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Fab
                        variant="extended"
                        color="secondary"
                        type="submit"
                        disabled={!isDirty || !isValid}
                    >
                        {/*{plcNewConfigurationMutation.isLoading ? (*/}
                        {/*    <CircularProgress size={18} style={{ marginRight: 8 }} />*/}
                        {/*) : (*/}
                        <>
                            <SaveIcon />
                            {t("salva")}
                        </>
                        {/*)}*/}
                    </Fab>
                    <Fab
                        variant="extended"
                        color="secondary"
                        onClick={() => setAnalysis(null)}
                    >
                        {/*{plcNewConfigurationMutation.isLoading ? (*/}
                        {/*    <CircularProgress size={18} style={{ marginRight: 8 }} />*/}
                        {/*) : (*/}
                        <>
                            {t("chiudi")}
                        </>
                        {/*)}*/}
                    </Fab>
                </DialogActions>
            </AppForm>
        </Dialog>
    );
})
