import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Collapse,
    Divider,
    Grid,
    Paper,
    Typography
} from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import { LGPCard } from './components/LGPcard'
import EnabledModules from '../BMS/components/EnabledModules'
import { removeSnakeCaseAndCapitalize } from '../../utils'
import ProceduresPanel from '../../components/Procedures/ProceduresPanel'
import Mappa from '../../components/Map/Map' // non chiamatelo Map che oscura il builtin
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { QuickFilterChip } from '../../components/QuickFilterChip'
import CompanyAnalitycsTable from './components/CompanyAnalitycsTable'
import { CustomQuickFiltersChip } from '../../components/CustomQuickFiltersChip'
import LoadingSpinner from '../../components/LoadingSpinner'
import { getActiveFiltersProps, quickFilterProps } from './quickFiltersConfig'
import useSearchableModel from '../../customHooks/useSearchableModel'
import { flattenDataModel } from "../../FormConfigs/filter_model_config"
import { getMethodologyStoredFiltersProvider, useStoredFilters } from "../../customHooks/storedFiltersContext"
import { useQueryCompany } from "../../customHooks/queryHooks/companyQueries"
import CompanyMethodologyExports from "../../components/CompanyMethodologyExports"

const useStyles = makeStyles((theme) => ({
    outerContainer: {
        height: 'calc(100vh - 64px)',
        overflow: 'auto',
        paddingRight: 30
        // height: 'max-content'
    },
    proceduresPanel: {
        height: '100%',
        position: 'fixed',
        top: 60,
        right: 30,
    },
    mapContainer: {
        height: '500px',
        // padding: '20px 0',
    },
    // companyName: {
    //     marginTop: '20px',
    //     paddingBottom: 0,
    // },
    // lgpLogo: {
    //     height: '90px',
    //     marginBottom: '20px',
    //     marginTop: '50pxP',
    // },
    advancedFiltersContainer: {
        padding: theme.spacing(2),
        height: '90%',
    },
    lgpPaper: {
        padding: theme.spacing(2),
        background: theme.palette.background.default,
    },
    cardContainer: {
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(3),
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '270px',
        },
        minWidth: '265px',
    },
    mainDivider: {
        backgroundColor: theme.palette.grey[500],
    },
}))

const AnalyticsDashboard = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [
        filters,
    ] = useStoredFilters((state) => [
        state.filters,
    ])

    const { data: company } = useQueryCompany()
    
    const { searchableModel } = useSearchableModel()
    const activeFiltersProps = React.useMemo(() => getActiveFiltersProps(filters, t), [filters, t])
    
    if (!company) return <LoadingSpinner />
    return (
        <Grid
            container
            justifyContent="space-between"
            style={{ paddingLeft: 30, paddingTop: 20 }}
            className={classes.outerContainer}
        >
            {/* SEZIONI */}
            <Grid item container xs={9} spacing={3}>
                {/* ACTIVE FILTERS */}
                <Grid item xs={12} {...(!activeFiltersProps?.length && {style: {padding: 0}})}>
                    <Collapse in={!!activeFiltersProps?.length}>
                        <Paper className={classes.lgpPaper}>
                            {/*<Typography className={classes.heading}>{t('active_filters')}</Typography>*/}
                            
                            <Grid container>
                                {/* FILTRI ATTIVI */}
                                <Grid item container xs={12} spacing={1}>
                                    {activeFiltersProps.map(({key, ...props}) => (
                                        <Grid item key={key}>
                                            <QuickFilterChip {...props} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Collapse>
                </Grid>
                {/*/!* ACCORDION *!/*/}
                <Grid item xs={12}>
                    {/* filtri */}
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>
                                {t('quick_filters')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                {/* LGP CDA COMPLESSIVA etc... */}
                                <Grid item xs={12}>
                                    <Grid item container xs={12}>
                                        <Typography variant="h6">LGP</Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{marginBottom: '20px', marginTop: '10px'}}
                                    >
                                        <Divider className={classes.mainDivider}/>
                                    </Grid>
                                    {quickFilterProps.map(({title, chipProps}) => (
                                        <Fragment key={title}>
                                            <Grid item container xs={12} style={{margin: '15px 0'}}>
                                                <Typography>{title}</Typography>
                                            </Grid>
                
                                            <Grid item container xs={12} spacing={1}>
                                                {chipProps.map((props, i) => (
                                                    <Grid item key={i}>
                                                        <QuickFilterChip {...props} label={t(props.label)} />
                                                    </Grid>
                                                ))}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        marginTop: '10px',
                                                        marginBottom: '5px',
                                                    }}
                                                >
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    ))}
                
                                    {/* CUSTOM FIELDS FILTERS */}
                                    {Object.entries(flattenDataModel({extra_fields: searchableModel?.extra_fields})).map(([path, dataType], i, arr) => {
                                        // const customFieldName = path.replace('extra_fields.', '')
                                        return (
                                            <Grid container item key={path}>
                                                <Grid item container xs={12} style={{margin: '15px 0'}}>
                                                    <Typography>
                                                        {removeSnakeCaseAndCapitalize(
                                                            path
                                                        ).split('.').slice(-2).join(' → ')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item container xs={12} spacing={1}>
                                                    <CustomQuickFiltersChip path={path} />
                                                    {i !== arr.length - 1 && <Grid
                                                        item
                                                        xs={12}
                                                        style={{
                                                            marginTop: '10px',
                                                            marginBottom: '5px',
                                                        }}
                                                    >
                                                        <Divider/>
                                                    </Grid>}
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    {/* Company Info */}
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>Pannello di controllo</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CompanyAnalitycsTable />
                        </AccordionDetails>
                    </Accordion>
                    {/* modules card */}
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>{t('modules')} </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EnabledModules />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {/*/!* map *!/*/}
                <Grid container item xs={12} className={classes.mapContainer}>
                    <Mappa />
                </Grid>
                {/* LGP section */}
                <Grid item xs={12}>
                    <Paper className={classes.lgpPaper}>
                        <LGPCard />
                    </Paper>
                </Grid>
                {/* export */}
                <Grid item xs={12}>
                    <Paper className={classes.lgpPaper}>
                        <CompanyMethodologyExports />
                    </Paper>
                </Grid>
            </Grid>

            {/* PROCEDURES PANEL */}
            <Grid item xs={3} container direction="column" className={classes.proceduresPanel}>
                <ProceduresPanel BIinterface={true} />
            </Grid>
        </Grid>
    )
}



const FiltersProvider = getMethodologyStoredFiltersProvider("lgp")

export default function WrappedComponent() {
    return (
        <FiltersProvider>
            <AnalyticsDashboard />
        </FiltersProvider>
    )
}
