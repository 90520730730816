import React from 'react'
import { TextField, Grid, Button, IconButton, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import CoordinatesPickerDialog from '../Coordinates/CoordinatesPickerDialog'
import EditLocationRoundedIcon from '@material-ui/icons/EditLocationRounded'
import { italyCenter } from '../../constants'
import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons'

export default function CoordinatesField({
    onChange,
    defaultValue,
    meta,
    info,
    onClickInfo,
    disableAll = false,
    value: propValue,
    drawCoordinatesArea = false,
}) {
    // React.useEffect(() => console.log("new coordinates field rendered, props: ", {
    //     onChange,
    //     defaultValue,
    //     meta,
    //     info,
    //     onClickInfo,
    //     disableAll,
    //     value: propValue,
    //     drawCoordinatesArea,
    // }), [])

    const { t } = useTranslation()
    const valueToUse = propValue || defaultValue
    const [propLat, propLng] = valueToUse?.coordinates || []
    const [lat, setLat] = React.useState(propLat)
    const [lng, setLng] = React.useState(propLng)

    React.useEffect(() => {
        if (lat !== propLat) setLat(propLat)
        if (lng !== propLng) setLng(propLng)
    }, [propLat, propLng, setLat, setLng, lat, lng])

    const [isDialogOpen, setIsDialogOpen] = React.useState(false)

    function changeLat(e) {
        const value = e.target.value
        if (value !== '') {
            onChange({
                type: 'Point',
                coordinates: [parseFloat(value), propLng],
            })
        }
    }

    function changeLng(e) {
        const value = e.target.value
        if (value !== '') {
            onChange({
                type: 'Point',
                coordinates: [propLat, parseFloat(value)],
            })
        }
    }

    function changeCoords({ lat, lng }) {
        onChange({
            type: 'Point',
            coordinates: [lat, lng],
        })
    }

    function changeCoordsArea(rectangle) {
        const NE = rectangle?.NE
        const NW = rectangle?.NW
        const SW = rectangle?.SW
        const SE = rectangle?.SE
        onChange([
            {
                type: 'Point',
                coordinates: [NE?.lat, NE?.lng],
            },
            {
                type: 'Point',
                coordinates: [NW?.lat, NW?.lng],
            },
            {
                type: 'Point',
                coordinates: [SW?.lat, SW?.lng],
            },
            {
                type: 'Point',
                coordinates: [SE?.lat, SE?.lng],
            },
        ])
    }
    return drawCoordinatesArea ? (
        // caso mappa per la selezione del punto o del rettangolo
        <>
            <Grid container alignItems="center" spacing={3}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setIsDialogOpen(true)}
                        size={'small'}
                        disabled={disableAll}
                        style={{
                            minWidth: 36,
                            maxWidth: 40,
                            flexBasis: 0,
                        }}
                    >
                        <EditLocationRoundedIcon />
                    </Button>
                </Grid>
                {info && (
                    <Grid item>
                        <Tooltip placement="top-end" title={info}>
                            <IconButton size="small" onClick={onClickInfo}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
            <CoordinatesPickerDialog
                drawCoordinatesArea={true}
                coordinates={[propLat || italyCenter.lat, propLng || italyCenter.lng]}
                initialZoom={propLat ? undefined : 5}
                onChange={changeCoordsArea}
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
                rectangleCoords={valueToUse}
            />
        </>
    ) : (
        // caso <input type=number /><input type=number />
        <>
            <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        type="number"
                        inputProps={{ step: 0.000000000000001 }}
                        variant="outlined"
                        label={t('latitude')}
                        value={lat ?? ''}
                        onChange={({ target: { value } }) => setLat(value)}
                        onBlur={changeLat}
                        required={meta?.required}
                        style={{ width: '100%' }}
                        disabled={disableAll}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        inputProps={{ step: 0.000000000000001 }}
                        type="number"
                        variant="outlined"
                        label={t('longitude')}
                        value={lng ?? ''}
                        onChange={({ target: { value } }) => setLng(value)}
                        onBlur={changeLng}
                        required={meta?.required}
                        style={{ width: '100%' }}
                        disabled={disableAll}
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setIsDialogOpen(true)}
                        size={'small'}
                        disabled={disableAll}
                        style={{
                            minWidth: 36,
                            maxWidth: 40,
                        }}
                    >
                        <EditLocationRoundedIcon />
                    </Button>
                </Grid>
                {info && (
                    <Grid item>
                        <Tooltip placement="top-end" title={info}>
                            <IconButton size="small" onClick={onClickInfo}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
            <CoordinatesPickerDialog
                coordinates={[propLat || italyCenter.lat, propLng || italyCenter.lng]}
                initialZoom={propLat ? 19 : 5}
                onChange={changeCoords}
                isOpen={isDialogOpen}
                setIsOpen={setIsDialogOpen}
            />
        </>
    )
}
