import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import {
    Grid,
    Button,
    CircularProgress,
} from '@material-ui/core'
import {
    Folder as FolderIcon,
    InsertInvitation as InsertInvitationIcon,
    ErrorOutline as ErrorOutlineIcon,
} from '@material-ui/icons'

import DashboardCard from './DashboardCard'
import { availableCompaniesQueries } from '../../../networking'
import { handleExcelResponseFile } from '../../../utils'
import dayjs from 'dayjs'
import LGPPieCharts from './LGPPieCharts'
import useCompanyId from "../../../customHooks/useCompanyId";
import { useQueryCompany, useQueryCompanyMethodologyAnalytics } from "../../../customHooks/queryHooks/companyQueries";
import { useStoredFilters } from "../../../customHooks/storedFiltersContext";
import { useExportMutation } from "../../../customHooks/mutationHooks/exportMutations";

export const LGPCard = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const filters = useStoredFilters((state) => state.filters)
    const { companyId } = useCompanyId()
    // const {data: company} = useQueryCompany()

    const {
        data: methodologyAnalytics = {},
        isFetching: isMethodologyAnalyticsFetching,
    } = useQueryCompanyMethodologyAnalytics()

    const { mutate: getLGPReport, isLoading: isLgpReportFileFetching } = useExportMutation({})



    const LgpCardsConfig = [
        {
            icon: <FolderIcon fontSize="large" />,
            count: methodologyAnalytics?.unlocked_procedures,
            type: 'pratiche',
            list: [
                { title: t('valutazione'), value: methodologyAnalytics?.confirmed_valutazione },
                {
                    title: t('prevalutazione'),
                    value: methodologyAnalytics?.confirmed_prevalutazione,
                },
            ],
        },
        {
            icon: <ErrorOutlineIcon fontSize="large" />,
            count: methodologyAnalytics?.count_PS,
            type: 'ponti PS',
            list: [
                { title: t('valutazione'), value: methodologyAnalytics?.count_PS_valutazione },
                {
                    title: t('prevalutazione'),
                    value: methodologyAnalytics?.count_PS_prevalutazione,
                },
            ],
        },
        {
            icon: <InsertInvitationIcon fontSize="large" />,
            count: methodologyAnalytics?.count_next_inspections?.[6],
            type: 'Ispezioni nei prossimi 6 mesi',
            buttons: [
                {
                    title: 'Vai al calendario',
                    onClick: () => {
                        history.push('/calendar')
                    },
                },
            ],
        },
    ]

    const downloadFile = () => {
        getLGPReport({
            companyId: companyId,
            methodology: 'mit_guidelines',
            filters: filters?.rules,
        })
    }
    return (
        <Grid item style={{ marginBottom: 20 }}>

            <Grid container item justifyContent="space-between" xs={12} spacing={1} style={{ marginBottom: '20px' }}>
                {LgpCardsConfig.map((card, i) => (
                    <DashboardCard
                        key={i}
                        icon={card.icon}
                        count={card.count}
                        type={card.type}
                        list={card.list}
                        buttons={card.buttons}
                    />
                ))}
            </Grid>
            {/* CDA */}
            <Grid item style={{ marginTop: 20, marginBottom: 20 }}>
                <Grid container direction={"column"} spacing={5} style={{alignItems: "center", marginTop: 20}}>
                    <LGPPieCharts methodologyAnalytics={methodologyAnalytics} isLoading={isMethodologyAnalyticsFetching} />
                </Grid>
            </Grid>
            {/*<Grid container item justifyContent="center">*/}
            {/*    <Grid item>*/}
            {/*        <Button*/}
            {/*            variant="contained"*/}
            {/*            color="secondary"*/}
            {/*            startIcon={*/}
            {/*                isLgpReportFileFetching ? (*/}
            {/*                    <CircularProgress size={25} variant="indeterminate" />*/}
            {/*                ) : undefined*/}
            {/*            }*/}
            {/*            disabled={isLgpReportFileFetching}*/}
            {/*            onClick={() => downloadFile()}*/}
            {/*        >*/}
            {/*            {t('extract')}*/}
            {/*        </Button>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
        </Grid>
    )
}
