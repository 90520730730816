import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'
import { PowerSettingsNew as PowerSettingsNewIcon, Done as DoneIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import AlertDialog from './Dialogs/AlertDialog'
import { companiesQueries, modulesQueries, queryClient } from '../networking'
import { useQueryCompany } from "../customHooks/queryHooks/companyQueries";

const useStyles = makeStyles((theme) => {
    return {
        pill: {
            marginLeft: 5,
            marginRight: 5,
        },
        disabledModulePill: {
            backgroundColor: theme.palette.background.disabledModulePill,
        },
    }
})

export default function ModulePill({ company, disableEdit = false }) {
    const classes = useStyles()
    const [alertDialogConfig, setAlertDialogConfig] = React.useState({})
    const { t } = useTranslation()
    const { data: allModules = [] } = useQuery(
        modulesQueries.getModules.name,
        modulesQueries.getModules.fn
    )
    const enableModule = useMutation(modulesQueries.enableModule.fn, {
        onSuccess: (result, variables) => {
            setAlertDialogConfig({ open: false })
            return Promise.all([
                queryClient.invalidateQueries(companiesQueries.getCompanies.name),
                queryClient.invalidateQueries(companiesQueries.getCompany.name, company.id),
            ])
        },
    })
    const disableModule = useMutation(modulesQueries.disableModule.fn, {
        onSuccess: (result, variables) => {
            setAlertDialogConfig({ open: false })
            return Promise.all([
                queryClient.invalidateQueries(companiesQueries.getCompanies.name),
                queryClient.invalidateQueries(companiesQueries.getCompany.name, company.id),
            ])
        },
    })

    const handleDisableModuleClick = (module) => (event) => {
        event.stopPropagation()
        event.preventDefault()
        setAlertDialogConfig({
            open: true,
            setIsOpen: (open) => setAlertDialogConfig({ open }),
            title: t('module_alert_title_disable', { module }),
            button: 'disable',
            onSubmit: () => disableModule.mutate({ companyId: company.id, module }),
        })
    }
    const handleEnableModuleClick = (module) => (event) => {
        event.stopPropagation()
        event.preventDefault()
        setAlertDialogConfig({
            open: true,
            setIsOpen: (open) => setAlertDialogConfig({ open }),
            title: t('module_alert_title_enable', { module }),
            button: 'enable',
            onSubmit: () => enableModule.mutate({ companyId: company.id, module }),
        })
    }

    return (
        <>
            {allModules.map((module) => {
                const isModuleEnabled = (company?.enabled_modules || []).includes(module)
                return (
                    <Chip
                        key={module}
                        classes={{
                            root: [
                                classes.pill,
                                isModuleEnabled ? undefined : classes.disabledModulePill,
                            ].join(' '),
                        }}
                        size="small"
                        label={module}
                        color={isModuleEnabled ? 'secondary' : 'primary'}
                        clickable
                        onDelete={
                            isModuleEnabled
                                ? handleDisableModuleClick(module)
                                : handleEnableModuleClick(module)
                        }
                        deleteIcon={isModuleEnabled ? <DoneIcon /> : <PowerSettingsNewIcon />}
                        onMouseDown={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                        }}
                        onClick={
                            isModuleEnabled
                                ? handleDisableModuleClick(module)
                                : handleEnableModuleClick(module)
                        }
                        disabled={disableEdit}
                    />
                )
            })}
            <AlertDialog
                open={Boolean(alertDialogConfig.open)}
                setIsOpen={alertDialogConfig.setIsOpen}
                title={alertDialogConfig.title}
                primaryButton={alertDialogConfig.button}
                onSubmitCb={alertDialogConfig.onSubmit}
            />
        </>
    )
}
