import { useStoredFilters } from "../storedFiltersContext";
import useCompanyId from "../useCompanyId";
import { getFiltersParam, handleExcelResponseFile } from "../../utils";
import { useMutation } from "react-query";
import { availableCompaniesQueries, notificationQueries, queryClient } from "../../networking";
import dayjs from "dayjs";
import axios from "axios";
import { useQueryCompany } from "../queryHooks/companyQueries";

export function useExportMutation({filtered= true}) {
    const filters = useStoredFilters(state => state.filters)
    const {companyId, methodology} = useCompanyId()
    const { company } = useQueryCompany()
    const params = getFiltersParam(filtered ? filters : {rules: {}})
    
    return useMutation(
        ['exportMutation', companyId, methodology, filters],
        async (data) => {
            const resp = await axios.post(`/export/${companyId}/${methodology === 'lgp' ? 'mit_guidelines' : methodology}?${params}`, data)
            await queryClient.invalidateQueries(notificationQueries.getNotifications.name)
            return resp.data
        },
        {
            onSuccess: (data) => {
                handleExcelResponseFile(data, `${company.name}-${dayjs().format('DD/MM/YYYY')}`)
            },
            enabled: !!(companyId && methodology)
        }
    )
    
}
